import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LogoComponent } from 'src/app/shared/components/logo/logo.component';

@Component({
  selector: 'app-auth-single-layout',
  templateUrl: './single-layout.component.html',
  styleUrls: ['./single-layout.component.scss'],
  imports: [RouterOutlet, LogoComponent]
})
export class SingleLayoutComponent {
  constructor(
  ) {
  }
}
